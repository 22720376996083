const excuteBridge = () => {
    ZWJSBridge.onReady(() => {
        //console.log("初始化完成后，执行bridge方法");
    });
};

//设置窗口标题
const setTitle = (title) => {
    excuteBridge();
    ZWJSBridge.setTitle({
        title: title,
    })
        .then((result) => {
            //console.log("####### enter ZWJSBridge result.  ##########");
        })
        .catch((error) => {
            //console.log("########  enter ZWJSBridge error. #########");
        });
};

// 呼叫手机
const callPhone = (phoneNumber) => {
    excuteBridge();
    ZWJSBridge.phoneCall({
        corpId: phoneNumber,
    })
        .then((result) => {
            //console.log("#### ZWJSBridge.phoneCall() log. ####");
        })
        .catch((error) => {
            //console.log("#### ZWJSBridge.phoneCall() error. ####");
        });
};

//获取位置信息
const getLocationMsg = (Username, openid) => {
    excuteBridge();
    let longitude = "";
    let latitude = "";
    ZWJSBridge.getLocation()
        .then((result) => {
            try {
                // 将对象转化为字符串
                var resultStr = JSON.stringify(result);
                //console.log("#### transfer to String , resultStr：  " + resultStr);
                // this.$store.commit("setlong", result.longitude);
                // this.$store.commit("setlati", result.latitude);
                getUserType(Username, openid, result.longitude, result.latitude);
            } catch (error) {
                //console.log("####  transfer failed. error:  " + error);
                getUserType(longitude, latitude);
            }
        })
        .catch((error) => {
            //console.log("#### ZWJSBridge.getLocation() error:  " + error);
            getUserType(longitude, latitude);
        });
};

//获取用户类型
const getUserType = (Username, openid, longitude, latitude) => {
    excuteBridge();
    let partytype = "";
    ZWJSBridge.getUserType()
        .then((result) => {
            // console.log("#### ZWJSBridge.getUserType() log. userType: " + result.userType + "####");
            if (result.userType == 0) {
                partytype = "public person";
            } else if (result.userType == 1) {
                partytype = "common person";
            } else if (result.userType == 2) {
                partytype = "legal person";
            }

            //console.log(
            //   "getUserType-Username=" +
            //     Username +
            //     "getUserType-openid=" +
            //     openid +
            //     "getUserType-longitude=" +
            //     longitude +
            //     "getUserType-latitude=" +
            //     latitude +
            //     "partytype=" +
            //     partytype
            // );
            baseAplus(Username, openid, longitude, latitude, partytype);
            //console.log("成功");
        })
        .catch((error) => {
            //console.log("#### ZWJSBridge.getUserType() error.####" + error);
            baseAplus(Username, openid, longitude, latitude, partytype);
            //console.log("成功");
        });
};

const baseAplus = function (
    thisusername,
    thisuserid,
    thislong,
    thislati,
    thisuserType
) {
    (function (w, d, s, q, i) {
        w[q] = w[q] || [];
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s);
        j.async = true;
        j.id = "beacon-aplus";
        j.src = "https://d.alicdn.com/alilog/mlog/aplus.js?id=202951085";
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "aplus_queue");

    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-waiting", "MAN"],
    });

    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-rhost-v", "alog.zjzwfw.gov.cn"],
    });

    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-rhost-g", "alog.zjzwfw.gov.cn"],
    });

    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["appId", "60506758"],
    });

    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_nick", thisusername],
    });

    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_id", thisuserid],
    });

    // 如采集⽤户信息是异步⾏为需要先执⾏这个BLOCK埋点
    //  aplus_queue.push({
    //      action: 'aplus.setMetaInfo',
    //       arguments: ['_hold', 'BLOCK']
    //      });

    // // 单⻚应⽤路由切换后 或 在异步获取到pv⽇志所需的参数后再执⾏sendPV：
    aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
            {
                is_auto: false,
            },
            {
                // ⾃定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套）， 如：
                miniAppId: "2001833223",
                miniAppName: "门前三包监管平台",
                long: thislong,
                lati: thislati,
                userType: thisuserType,
            },
        ],
    });

    // 如采集⽤户信息是异步⾏为，需要先设置完⽤户信息后再执⾏这个START埋点
    // 此时被block住的⽇志会携带上⽤户信息逐条发出
    //  aplus_queue.push({
    //      action: 'aplus.setMetaInfo',
    //      arguments: ['_hold', 'START']
    //     });
};

//导出模块
export default {
    getLocationMsg,
    getUserType,
    baseAplus,
    callPhone,
    setTitle,
};
