import * as CryptoJS from "crypto-js/crypto-js";

// const DESKey =
//   "45278954896582656547523589654125636851258965874523698541235896521154445896578542504165874526688545823698554569852145899654489526";

const DESKey = "58469854";

//加密
function encryptDes(data) {
    const keyHex = CryptoJS.enc.Utf8.parse(DESKey);
    //模式为ECB padding为Pkcs7
    const encrypted = CryptoJS.DES.encrypt(data, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    //加密出来是一个16进制的字符串
    return encrypted.ciphertext.toString();
}

//解密
function decryptDes(data) {
    const keyHex = CryptoJS.enc.Utf8.parse(DESKey);
    const decrypted = CryptoJS.DES.decrypt(
        {
            ciphertext: CryptoJS.enc.Hex.parse(data),
        },
        keyHex,
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }
    );
    //以utf-8的形式输出解密过后内容
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export default {
    encryptDes,
    decryptDes,
};
